.testimonial-carousel {
    padding: 20px;
}

.testimonial-slide {
    padding: 25px 50px;
}

.testimonial-text {
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 10px;
}

.testimonial-name {
    font-size: 1em;
    font-weight: bold;
    text-align: center;
}