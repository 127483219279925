.gameaway-logo {
  h1 {
    font-size: 2rem;
    margin: 0;
    cursor: pointer;
    color: var(--secondary-dark);
  }

  h4 {
    font-size: 1rem;
    margin: 0.4rem 0 0 0;
  }
}