.temporary-warning-banner {
    width: 100%;
    color: lightcoral;
    font-size: 1.2rem;
    font-weight: bolder;
}

.address-line {
    margin: 0rem 0; /* Adjust this value according to your preference */
}

.landingpage-container {
    padding-top: 0px;
    max-width: 1245px;
    margin: 0 auto;
    padding-left: 24px;
    padding-right: 24px;

    .footer {
        border-top: 2px solid blue;
        padding: 24px 0;
        color: white;
    }

    .footer-links {
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 24px 0;
        list-style-type: none;
    }

    .footer-links a {
        margin: 5px 10px;
    }

    .custom-button:hover {
        filter: brightness(1.1);
    }

    .landingpage-logo {
        width: 220px;
    }

    .landingpage-buttons {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .landingpage-viewport {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 700px;

        .landingpage-title {
            margin: 0;
            font-weight: 700;
            font-size: 48px;
            line-height: 72px;
        }

        .landingpage-subtitle {
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 0;
        }

       .custom-button {
           font-weight: 700;
           font-size: 18px;
           line-height: 24px;
           height: 60px;
           margin: 48px 0;
        }

        .controller-image {
            width: 150px;
            height: auto;
        }

        .game-column {
            display: flex;
            justify-content: center;
            flex-direction: row;
            img {
                padding: 10px;
            }
        }

        // Mobile
        .right {
            flex-direction: row;
            overflow: hidden;
            img {
                height: 120px;
                width: auto;
            }
        }
    }

    .how-it-works-section, .game-list-section, .testimonial-section, .youtube-section {
        border-top: 2px solid blue;
        margin-top: 60px;
        padding: 40px 0;

        h2 {
            margin-top: 0;
        }
    }

    .youtube {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        @media (max-width: 767px) {
            flex-wrap: wrap;
            gap: 32px;
        }

        iframe {
            max-width: 100%;
            padding: 0 16px;
            width: 100%;
            height: 315px;
            @media (max-width: 767px) {
            }
        }
    }

    .how-it-works-header {
        margin-top: 0;
    }

    .how-it-works {
        display: flex;
        flex-direction: column;
        width: 100%;

        .how-steps {
            line-height: 1.625;
            font-size: 1rem;
            padding: 16px 24px;
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;

            .how-step-logo {
                margin-right: 12px;
                font-size: 3.5rem;
                text-align: center;
                display: inline-block;
            }

            a {
                text-decoration: underline;
            }
        }
    }

    .game-list-section {
        .you-own-game-block {
            margin: 0px 8px 32px 0;
            .you-own-game-new {
                color: #FF9933;
                margin-right: 8px;
            }
        }
        .game-list-body {
            margin-top: 15px
        }

        .game-list {
            display: flex;
            flex-direction: column;
        }

        .game-in-list {
            padding-bottom: 12px;
        }
    }

    // Desktop
    @media screen and (min-width: 1024px) {
        .game-list-section {
            .game-list {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
            }
            .game-list.steam-list {
                height: 200px;
            }
            .note-own-games {
                margin-bottom: 32px;
            }

        }

        .landingpage-viewport {
            flex-direction: row;

            .game-column {
                flex-direction: column;
            }

            .left {
                flex: 2;
                display: flex;
                justify-content: center;
                //align-items: center;
                flex-direction: column;
            }

            .right {
                flex: 1;
                display: flex;
                justify-content: space-evenly;
                padding: 16px;

                img {
                    width: 150px;
                    height: auto;
                }
            }

            .landingpage-subtitle, .custom-button {
                width: 70%;
            }
        }

        .how-it-works-section {
            margin-bottom: 40px;
        }

        .how-it-works {
            flex-direction: row;
            justify-content: space-evenly;

            .how-steps {
                width: 33%;
                flex-direction: column;

                &:first-of-type {
                    padding-left: 0;
                }
            }
        }
    }
}

.page-container {
    margin: 30px;
    font-family: Arial, sans-serif;
  }

  .page-container h1 {
    margin-bottom: 20px;
  }

  .page-container h2 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .page-container p {
    margin-bottom: 10px;
  }

  .page-container ul {
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .page-container ul li {
    margin-bottom: 5px;
  }

  .page-container a {
    color: #3498db;
    text-decoration: none;
  }

  .page-container a:hover {
    text-decoration: underline;
}

