.moonlight-download-links {
  a {
    margin: 0 4px;
  }

  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: white;
  }
}
