@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Default */
@layer base {
  :root {
    --background: #09090B;
    --foreground: #FAFAFA;
    --card: #09090B;
    --card-foreground: #fafafa;
    --popover: #09090B;
    --popover-foreground: #fafafa;
    --primary: #a21caf;
    --primary-foreground: #fafafa;
    --secondary: #27272A;
    --secondary-foreground: #fafafa;
    --muted: #27272A;
    --muted-foreground: #94969c;
    --accent: #06B6D4;
    --accent-foreground: #FAFAFA;
    --destructive: #7F1D1D;
    --destructive-foreground: #FEF2F2;
    --border: #27272A;
    --input: #27272A;
    --ring: #D4D4D8;
    --ring-offset: #0c111d;
    --radius: 0.375rem;
    --focus: #27272A;
    --border-secondary: #27272A;
    --border-primary: #3F3F46;
    --border-tertiary: #333741;
    --chart-1: #2563EB;
    --chart-2: #DC2626;
    --chart-3: #EA580C;
    --chart-4: #AF57DB;
    --chart-5: #059669;
    --sidebar-background: #111827;
    --sidebar-foreground: #F4F4F5;
    --sidebar-primary: #1d4ed8;
    --sidebar-primary-foreground: #ffffff;
    --sidebar-accent: #09090B;
    --sidebar-accent-foreground: #F4F4F5;
    --sidebar-border: #27272A;
    --sidebar-ring: #D4D4D8;
    --input-background: #09090B;
    --button-background: #1f242f;
  }
}