.temporary-warning-banner {
  width: 100%;
  color: lightcoral;
  font-size: 1.2rem;
}

.dashboardpage {
  position: relative;
  .dynamic-element {
    margin: 16px 0;
    display: flex;
    align-items: center;
    span {
      display: inline-flex;
      align-items: center;
    }
  }

  .buy-section {
    display: inline-block;
    width: 100%;

    a,
    a:link,
    a:visited,
    a:hover,
    a:active {
      text-decoration: none;
    }

    @media screen and (min-width: 767px) {
      width: auto;
    }
  }

  .buttons {
    margin-top: 16px;
  }

  .stop-gamingrig-button {
    background: #67A8FC;
  }

  .purchase-hours-container {
    margin: 25px 0;
  }

  .purchase-hours {
    padding: 10px;
    background: forestgreen;
    color: white;
    text-decoration: none;
  }

  .loader-container {
    height: auto;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    //position: absolute;
    //top: -52px;
  }

  .dashboard-loader {
    height: 42px;
    &.pin {
      height: 21px;
    }
  }

  .copied-tooltip {
    border: 2px solid green;
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .copied-tooltip.show {
    opacity: 1;
  }

  .dynamic-element-label {
    margin: 0 5px;
  }

  .ip-addr {
    margin-right: 5px;
  }

  .running-state-container {
    color: lightgreen;
  }

  .pin-check {
    color: lightgreen;
  }

  .suggest-game-block {
    margin: 16px 0;
    span {
      display: inline-block;
      margin-bottom: 6px;
    }
  }

  .suggestion-confirmation {
    color: lightgreen;
    font-size: 12px;
    margin-left: 8px;
  }

  .plan-validity {
    margin-top: 16px;
    color: lightgreen;
  }

  .progress-bar-container {
    display: block;
    width: 300px; /* Fixed width */
    height: 10px; /* Reduced height */
    background: #ddd; /* Grey background */
    border-radius: 5px; /* Rounded corners */
    margin-top: 20px; /* Gap between the booting message and the progress bar */
    align-self: flex-start;
}
  
  /* CSS for the actual progress bar */
  .progress-bar {
    height: 10px; /* Full height */
    background: #32CD32; /* Light green background */
    border-radius: 5px; /* Rounded corners */
  }
  
}