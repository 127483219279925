.pay-or-start-trial {
  section {
    margin-top: 32px;
  }

  strong {
    color: #E8AA42;
    a, a:visited {
      color: #E8AA42;
    }
  }

  .moonlight-text {
    margin-top: 48px;
  }
}