.footer {
    margin-top: 50px;
    border-top: 1px solid #cccccc;
    padding: 20px 0;
    color: #7a7a7a;
}

.footer-links {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-left: 0;  /* This rule removes the default padding */
}

.footer-links a {
    margin-right: 10px;
}

.footer-links a:last-child {
    margin-right: 0;
}

.footer-copyright {
    text-align: center; /* This rule centers the copyright text */
}