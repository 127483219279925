.gameaway-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;

    .logout-button {
      cursor: pointer;
      height: 30px;
    }
}