.custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--secondary-dark);
  color: var(--primary-dark);
  font-weight: 600;
  border: none;
  width: 100%;
  height: 48px;
  left: 15px;
  top: 243px;
  margin: 10px 0;
  border-radius: 4px;
  font-size: 1rem;
  @media screen and (min-width: 767px) {
    width: 380px
  }
  svg {
    margin-right: 8px;
  }
}
.custom-button.secondary {
  background-color: #2E245D;
  color: #E5E5E5;
  border: 1px solid #E5E5E5
}

.custom-button:disabled {
  background: #443B6E;
  color: #221855;
  cursor: default;
}